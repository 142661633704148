<template>
	<Transition
		name="expand"
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
	>
		<div v-show="showIf">
			<slot />
		</div>
	</Transition>
</template>

<script>
/**
 * Usage: Use instead of vue transition element to expand element to height auto.
 * Pass prop :show-if to TransitionHeight instead of v-if on child element.
 * The v-if is set directly on the TransitionHeight component.
 * This ensures that padding and margins are expanded correctly.
 */
export default defineComponent({
	name: "TnTransitionHeight",

	props: {
		showIf: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		enter(element) {
			element.style.width = getComputedStyle(element).width;
			element.style.position = "absolute";
			element.style.visibility = "hidden";
			element.style.height = "auto";

			const height = getComputedStyle(element).height;

			element.style.width = "";
			element.style.position = "";
			element.style.visibility = "";
			element.style.height = 0;

			// Force re-render og element.
			getComputedStyle(element).height;

			// height 0 must have been set. Hence timeout.
			setTimeout(() => {
				element.style.height = height;
			});
		},
		afterEnter(element) {
			element.style.height = "auto";
		},
		leave(element) {
			element.style.height = getComputedStyle(element).height;

			getComputedStyle(element).height;

			setTimeout(() => {
				element.style.height = 0;
			});
		},
	},
});
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
	transition: all 300ms ease-in-out;
	overflow: hidden;
	opacity: 1;
}

.expand-enter-from,
.expand-leave-to {
	height: 0;
	opacity: 0;
}
</style>
